import React, { useState } from 'react';

// Import images
import img1 from './images/1.jpeg';
import img2 from './images/9.jpeg';
import img3 from './images/10.jpeg';
import img4 from './images/11.jpeg';
import img5 from './images/12.jpeg';

const Categories = () => {
  const categories = [
    { name: 'Bedsheets', image: img1 },
    { name: 'Cushion Cover', image: img2 },
    { name: 'Towels', image: img3 },
    { name: 'Cushions', image: img4 },
    { name: 'Doormats', image: img5 },
  ];

  const [startIndex, setStartIndex] = useState(0);

  // Move the slider to the left (previous)
  const slideLeft = () => {
    if (startIndex > 0) setStartIndex(startIndex - 1);
  };

  // Move the slider to the right (next)
  const slideRight = () => {
    if (startIndex < categories.length - 2) setStartIndex(startIndex + 1);
  };

  return (
    <section className="my-8 px-4">
      <h2 className="text-2xl font-semibold text-center mb-6">Categories</h2>

      {/* Container for categories with responsive grid layout */}
      <div className="relative flex justify-center items-center">
        {/* Left arrow button */}
        <button
          onClick={slideLeft}
          className="absolute left-2 text-gray-800 hover:text-blue-600 focus:outline-none lg:hidden"
          aria-label="Previous"
        >
          ◀
        </button>

        <div className="overflow-hidden w-full max-w-screen-lg">
          <div
            className="flex lg:flex-wrap lg:justify-center lg:space-x-6 transition-transform duration-300 ease-in-out"
            style={{ transform: `translateX(-${startIndex * 50}%)` }}
          >
            {categories.map((category, index) => (
              <div
                key={index}
                className="flex-none w-1/2 lg:w-auto text-center p-4"
              >
                <div className="w-36 h-36 lg:w-25 lg:h-25 flex items-center justify-center rounded-full bg-gray-200 mx-auto overflow-hidden">
                  <img src={category.image} alt={category.name} className="w-full h-full object-cover" />
                </div>
                <p className="mt-2 text-lg font-medium">{category.name}</p>
              </div>
            ))}
          </div>
        </div>

        {/* Right arrow button */}
        <button
          onClick={slideRight}
          className="absolute right-2 text-gray-800 hover:text-blue-600 focus:outline-none lg:hidden"
          aria-label="Next"
        >
          ▶
        </button>
      </div>
    </section>
  );
};

export default Categories;
