import React from 'react';
// Import images
import img13 from './images/13.jpeg';
import img14 from './images/14.jpeg';
import img15 from './images/15.jpeg';
import img16 from './images/16.jpeg';
import img17 from './images/17.jpeg';
import img18 from './images/18.jpeg';
import img19 from './images/19.jpeg';
import img20 from './images/20.jpeg';


const Cushions = () => {
    const products = [
        { id: 1, title: 'Knod Brown Cushion Cover', price: '899', image: img13 },
        { id: 2, title: 'Khaadi Premium', price: '899', image: img14 },
        { id: 3, title: 'Mehroom Blush Cushions', price: '899', image: img15 },
        { id: 4, title: 'Brown Velvet King', price: '899', image: img16 },
        { id: 5, title: 'Badge Velvet Premium', price: '899', image: img17 },
        { id: 6, title: 'Black Velvet Premium', price: '899', image: img18 },
        { id: 7, title: 'Grey Floral Print Cover', price: '899', image: img19 },
        { id: 8, title: 'Floral Print Cover', price: '899', image: img20 },
      ];
      
  const handleWhatsAppRedirect = (title, price) => {
    const phoneNumber = '9416620186'; 
    const message = `Hello! I am interested in buying the product "${title}" priced at ₹${price}.`;
    const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
    window.open(url, '_blank');
  };
  return (
    <>
      <section className="my-8 px-4">
      <h2 className="text-2xl font-semibold text-center mb-6">Cushion Cover</h2>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
        {products.map((product) => (
          <div key={product.id} className="border rounded-lg p-4 text-center">
            <img
              src={product.image}
              alt={product.title}
              className="w-full h-40 object-contain mb-4 rounded"
            />
            <h3 className="text-lg font-medium mb-2">{product.title}</h3>
            <p className="text-lg text-gray-700 mb-2">₹{product.price}</p>
            <button
              onClick={() => handleWhatsAppRedirect(product.title, product.price)}
              className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
            >
              Add to Cart
            </button>
          </div>
        ))}
      </div>
    </section>
    </>
  )
}

export default Cushions
