import React from 'react';
import offering from './images/offer.jpeg';

const OfferSection = () => {
  const offer = [
    {
      image: offering,
    }
  ];

  return (
    <div className="container">
      <div className="row mt-5">
        <div className="col-md-12 col-lg-12 col-sm-12 mt-5 d-flex justify-content-center">
          <img src={offer[0].image} alt="Offer Image" className="w-full h-full object-cover" />
        </div>
      </div>
    </div>
  );
}

export default OfferSection;
