import React, { useState } from 'react';
import bannerImage from './banner-canva.jpg'; // Import the local image

const BannerSlider = () => {
  // Using imported local image for the slides
  const slides = [
    {
      id: 1,
      image: bannerImage,
      caption: 'Banner 1 Caption',
    },
    {
      id: 2,
      image: bannerImage,
      caption: 'Banner 2 Caption',
    },
    {
      id: 3,
      image: bannerImage,
      caption: 'Banner 3 Caption',
    },
  ];

  const [currentSlide, setCurrentSlide] = useState(0);

  const nextSlide = () => {
    setCurrentSlide((prevIndex) => (prevIndex + 1) % slides.length);
  };

  const prevSlide = () => {
    setCurrentSlide((prevIndex) => (prevIndex - 1 + slides.length) % slides.length);
  };

  return (
    <div className="relative w-full max-w-3xl mx-auto mt-8 overflow-hidden rounded-lg shadow-lg">
      <div
        className="flex transition-transform duration-700"
        style={{ transform: `translateX(-${currentSlide * 100}%)` }}
      >
        {slides.map((slide) => (
          <div key={slide.id} className="min-w-full relative">
            <img src={slide.image} alt={slide.caption} className="w-full h-64 object-cover" />
            <div className="absolute bottom-4 left-4 bg-black bg-opacity-50 text-white text-sm py-2 px-4 rounded">
              {slide.caption}
            </div>
          </div>
        ))}
      </div>

      {/* Previous Button */}
      <button
        className="absolute top-1/2 left-4 transform -translate-y-1/2 bg-black bg-opacity-50 text-white p-2 rounded-full focus:outline-none"
        onClick={prevSlide}
      >
        ❮
      </button>

      {/* Next Button */}
      <button
        className="absolute top-1/2 right-4 transform -translate-y-1/2 bg-black bg-opacity-50 text-white p-2 rounded-full focus:outline-none"
        onClick={nextSlide}
      >
        ❯
      </button>
    </div>
  );
};

export default BannerSlider;
