import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './HomePage';
import Header from './Header';
import Footer from './Footer';
import Bedsheets from './Bedsheets';
import Cushions from './Cushions';

function App() {
  return (
    <Router>
      <div className="App">
      <Header />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/bedsheets" element={<Bedsheets />} />
          <Route path="/cushions" element={<Cushions />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
