import React from 'react';

// Import images
import img1 from './images/1.jpeg';
import img2 from './images/2.jpeg';
import img3 from './images/3.jpeg';
import img4 from './images/4.jpeg';
import img5 from './images/5.jpeg';
import img6 from './images/6.jpeg';
import img7 from './images/7.jpeg';
import img8 from './images/8.jpeg';

const Bedsheets = () => {
  // Sample product data with imported images
  const products = [
    { id: 1, title: 'Pure Cotton Bedsheets', price: '899', image: img1 },
    { id: 2, title: 'Jaipuri Pure Cotton Bedsheet', price: '899', image: img2 },
    { id: 3, title: 'Pure Cotton Yellow Floral Bedsheets', price: '899', image: img3 },
    { id: 4, title: 'Blue Lagoon Cartoon Print Bedsheet Pure Cotton', price: '999', image: img4 },
    { id: 5, title: 'King Size Elephant print pure cotton Bedsheet', price: '1399', image: img5 },
    { id: 6, title: 'King Size Blue Floral pure cotton Bedsheet', price: '1399', image: img6 },
    { id: 7, title: 'King Size Block print pure cotton Bedsheet', price: '1399', image: img7 },
    { id: 8, title: 'Red Cherry Woolen Bedsheet', price: '899', image: img8 },
  ];

  // Function to redirect to WhatsApp with product details
  const handleWhatsAppRedirect = (title, price) => {
    const phoneNumber = '9416620186'; 
    const message = `Hello! I am interested in buying the product "${title}" priced at ₹${price}.`;
    const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
    window.open(url, '_blank');
  };

  return (
    <section className="my-8 px-4">
      <h2 className="text-2xl font-semibold text-center mb-6">Our Bedsheets</h2>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
        {products.map((product) => (
          <div key={product.id} className="border rounded-lg p-4 text-center">
            <img
              src={product.image}
              alt={product.title}
              className="w-full h-40 object-contain mb-4 rounded"
            />
            <h3 className="text-lg font-medium mb-2">{product.title}</h3>
            <p className="text-lg text-gray-700 mb-2">₹{product.price}</p>
            <button
              onClick={() => handleWhatsAppRedirect(product.title, product.price)}
              className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
            >
              Add to Cart
            </button>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Bedsheets;
