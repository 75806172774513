import React from 'react'

const Footer = () => {
  return (
    <>
     <footer className="bg-gray-800 text-white py-8 px-4 text-center md:text-left">
      <div className="container mx-auto flex flex-col space-y-6 md:space-y-4 md:flex-row md:justify-between md:items-center">
        
        {/* Row 1: Brand Name */}
        <div className="flex-1">
          <h1 className="text-3xl font-bold">HeavenDecor</h1>
        </div>

        {/* Row 2: Product Categories */}
        <div className="flex-1">
          <ul className="    md:space-y-0 md:space-x-4">
            <li><a href="#" className="hover:underline line-height-30">Woolen Bedsheets</a></li>
            <li><a href="#" className="hover:underline line-height-30">Cotton Bedsheets</a></li>
            <li><a href="#" className="hover:underline line-height-30">King Size Bedsheets</a></li>
            <li><a href="#" className="hover:underline line-height-30">Single Bed Bedsheets</a></li>
          </ul>
        </div>

        {/* Row 3: Policies */}
        <div className="flex-1">
          <ul className="space-y-2 md:space-y-0 md:space-x-4">
            <li><a href="#" className="hover:underline line-height-30">Privacy Policy</a></li>
            <li><a href="#" className="hover:underline line-height-30">Terms & Conditions</a></li>
            <li><a href="#" className="hover:underline line-height-30">Return Policy</a></li>
          </ul>
        </div>
      </div>
    </footer> 
    </>
  )
}

export default Footer
