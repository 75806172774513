import React, { useState } from 'react';
import logo from './images/logo.png';

const Header = () => {
  // State to manage the mobile menu toggle
  const [isOpen, setIsOpen] = useState(false);

  // Toggle function to open/close the menu
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className=" px-6 py-4 flex items-center justify-between relative">
      {/* Logo aligned to the left */}
      <a className="text-2xl font-semibold text-dark" href="/"><img src={logo}/></a>

      {/* Toggle button for mobile menu */}
      <button
        className="lg:hidden text-dark p-2"
        type="button"
        onClick={toggleMenu}
        aria-label="Toggle navigation"
      >
        {isOpen ? (
          // Show the cross (X) when the menu is open
          <span className="block w-6 h-0.5 bg-black rotate-45 translate-y-1.5"></span>
        ) : (
          <>
            <span className="block w-6 h-0.5 bg-black mb-1"></span>
            <span className="block w-6 h-0.5 bg-black"></span>
          </>
        )}
      </button>

      {/* Navbar links, centered on large screens */}
      <div className={`lg:flex ${isOpen ? 'absolute top-16 left-0 w-full bg-gray-800 z-50' : 'hidden'} lg:w-auto items-center justify-center space-x-6 py-4 lg:static`}>
        <ul className="flex flex-col lg:flex-row space-y-4 lg:space-y-0 text-dark text-lg">
          <li className="hover:text-blue-400 mr-5">
            <a href="#">Home</a>
          </li>
          <li className="hover:text-blue-400 mr-5">
            <a href="#">Products</a>
          </li>
          <li className="hover:text-blue-400 mr-5">
            <a href="#">About Us</a>
          </li>
          <li className="hover:text-blue-400 mr-5">
            <a href="#">Contact</a>
          </li>
        </ul>
      </div>
    </nav>
  );
}

export default Header;
