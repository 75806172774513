import React from 'react';

import { Link } from 'react-router-dom';
import img13 from './images/13.jpeg';
import img14 from './images/14.jpeg';
import img15 from './images/15.jpeg';
import img16 from './images/16.jpeg';
import img17 from './images/17.jpeg';

const ProductCollection = () => {
    // Sample product data with imported images
  const products = [
    { id: 1, title: 'Knod Brown Cushion Cover', price: '899', image: img13 },
    { id: 2, title: 'Khaadi Premium', price: '899', image: img14 },
    { id: 3, title: 'Mehroom Blush Cushions', price: '899', image: img15 },
    { id: 4, title: 'Brown Velvet King', price: '899', image: img16 },
    { id: 5, title: 'Black Velvet Premium', price: '899', image: img17 },
  ];

  // Function to redirect to WhatsApp with product details
  const handleWhatsAppRedirect = (title, price) => {
    const phoneNumber = '9416620186'; 
    const message = `Hello! I am interested in buying the product "${title}" priced at ₹${price}.`;
    const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
    window.open(url, '_blank');
  };
  return (
    <>
      <section className="my-8 px-4">
      <h2 className="text-2xl font-semibold text-center mb-6">Cushion Covers</h2>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-5 gap-4">
        {products.map((product) => (
          <div key={product.id} className="border rounded-lg p-4 text-center">
            <img
              src={product.image}
              alt={product.title}
              className="w-full h-40 object-contain mb-4 rounded"
            />
            <h3 className="text-lg font-medium mb-2">{product.title}</h3>
            <p className="text-lg text-gray-700 mb-2">₹{product.price}</p>
            <button
              onClick={() => handleWhatsAppRedirect(product.title, product.price)}
              className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
            >
              Add to Cart
            </button>
          </div>
        ))}
      </div>
      <h6 className="text-2xl font-semibold text-center mb-6 mt-10 text-primary"><Link to="/cushions">See All...</Link></h6>
    </section>
    </>
  )
}

export default ProductCollection
